import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Features from "./components/Features";
import PrivacyPolicy from "./components/PrivacyPolicy"; // Import PrivacyPolicy
import TermsAndConditions from "./components/TermsAndConditions"; // Import TermsAndConditions

function App() {
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const openContact = () => setIsContactOpen(true);
  const closeContact = () => setIsContactOpen(false);

  // Simulate a loading delay to ensure the animation completes
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Match duration of the animation
    return () => clearTimeout(timer);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      {isLoading ? (
        <div className="loader-container">
          {/* Diagonal sliding gray background */}
          <div className="loader-background"></div>
          {/* Centered logo */}
          <img
            src={require("./assets/images/base_logo_transparent_background.png")}
            alt="Loading..."
            className="loader-image"
          />
        </div>
      ) : (
        <div className="font-sans antialiased text-gray-900">
          <Router>
            <Navigation openContact={openContact} />
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <main>
                      <Hero openContact={openContact} />
                      {isContactOpen && (
                        <div className="fixed inset-0 z-50 flex">
                          <div
                            className="absolute inset-0 bg-black/50 backdrop-blur-sm"
                            onClick={closeContact}
                          />
                          <div className="relative ml-auto h-full w-full sm:w-1/2 bg-white transform transition-transform duration-300">
                            <ContactUs onClose={closeContact} />
                          </div>
                        </div>
                      )}
                    </main>
                    <Features />
                  </>
                }
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            </Routes>
            <Footer />
          </Router>
        </div>
      )}
    </I18nextProvider>
  );
}

export default App;
