// src/components/Hero.jsx

import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Container from "./Container.jsx"; // If you're using a Container component

const Hero = ({ openContact }) => {
  const { t } = useTranslation();

  return (
    <section 
      className="relative py-16 overflow-hidden bg-gradient-to-b" 
      id="home"
    >
      {/* Similar radial background shapes from Features */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,rgba(120,113,108,0.2),transparent_70%)]" />
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_70%,rgba(168,162,158,0.15),transparent_70%)]" />

      <Container>
        <motion.div
          // Animate the container similar to how you did in Features
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", bounce: 0.4, duration: 0.8 }}
          className="text-center mt-12"
        >
          <h1 
            // A heading style that borrows the gradient text from Features
            className="text-5xl md:text-6xl font-black sm:mt-40 mb-4 py-3 bg-gradient-to-r from-amber-600 via-stone-600 to-neutral-400 bg-clip-text text-transparent"
          >
            {t("hero.titlePart1")}{" "}
            <span className="text-amber-300">
              {t("hero.titlePart2")}
            </span>
          </h1>

          <p className="text-lg text-stone-600 max-w-xl mx-auto">
            {t("hero.description")}
          </p>

          {/* Optional glowing orb or radial shape behind the button, reminiscent of the FeatureCard styling */}
          <div className="absolute -right-16 -bottom-16 w-48 h-48 bg-stone-200/10 rounded-full blur-3xl transition-all duration-700" />
        </motion.div>
      </Container>
    </section>
  );
};

export default Hero;
