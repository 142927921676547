import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 mt-20 text-gray-800">
      <h1 className="text-4xl font-bold text-indigo-600 mb-8">Privacy Policy</h1>
      <p className="text-lg mb-6">
        At <span className="font-semibold">Oknenor</span>, your privacy is a top
        priority. This Privacy Policy explains how we collect, use, and protect
        the information you share with us while using our services.
      </p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          1. Information We Collect
        </h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            <b>Personal Data:</b> Information like name and preferences
            associated with your interaction with our services.
          </li>
          <li>
            <b>Usage Data:</b> Details about how you navigate and interact with
            our platform.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          2. How We Use Your Information
        </h2>
        <p className="mb-4">
          The information we collect is used to enhance your experience,
          including:
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Delivering personalized content and features.</li>
          <li>Improving our platform's performance and usability.</li>
          <li>
            Understanding trends to make our platform more intuitive and
            effective.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          3. Cookies and Tracking
        </h2>
        <p>
          We use cookies to ensure smooth navigation and a tailored experience.
          You can adjust cookie preferences through your browser settings.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          4. Data Security
        </h2>
        <p>
          We employ robust security protocols to safeguard your information.
          However, it's important to note that no system is entirely immune to
          breaches.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          5. Changes to Our Privacy Policy
        </h2>
        <p>
          This policy may be updated periodically to reflect changes in our
          practices. Please revisit this page to stay informed about updates.
        </p>
      </section>

      <section>
        <p className="text-lg font-semibold text-center mt-12">
          Thank you for trusting <span className="font-bold">Oknenor</span> with
          your privacy.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
