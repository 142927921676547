import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import DubaiTime from "./DubaiTime";

const Footer = () => {
  return (
    <footer>
      <div className="bg-gray-100 mt-20">
        <div className="max-w-screen-lg px-4 sm:px-6 text-gray-800 sm:grid md:grid-cols-4 sm:grid-cols-2 mx-auto">
          {/* Company Branding */}
          <div className="p-5">
            <h3 className="font-bold text-xl text-indigo-600">Oknenor</h3>
          </div>

          {/* Support */}
          <div className="p-5">
            <div className="text-sm uppercase text-indigo-600 font-bold">Support</div>
            <Link className="my-3 block hover:underline" to="/privacy-policy">
              Privacy Policy
              <span className="text-teal-600 text-xs p-1"></span>
            </Link>
            <Link className="my-3 block hover:underline" to="/terms-and-conditions">
              Terms and Conditions
              <span className="text-teal-600 text-xs p-1"></span>
            </Link>
            {/* Link to PDF */}
            <a
              className="my-3 block hover:underline"
              href="/Documents/Business_License.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              BUSINESS LICENCE
            </a>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 pt-2">
        <div
          className="flex flex-col md:flex-row pb-5 px-3 m-auto pt-5 border-t text-gray-800 text-sm max-w-screen-lg items-center justify-between"
        >
          {/* Social icons */}
          <div className="flex mt-2 mb-5 md:mt-0">
            <a
              href="https://www.facebook.com"
              className="w-6 mx-1"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebookF
                className="text-gray-500 hover:text-indigo-600"
                size={24}
              />
            </a>

            <a
              href="https://www.instagram.com"
              className="w-6 mx-1"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram
                className="text-gray-500 hover:text-indigo-600"
                size={24}
              />
            </a>
          </div>

          {/* Language Switcher */}
          <LanguageSwitcher isDark={false} />

          {/* Copyright + Company Info */}
          <div className="my-5 md:my-0 text-center md:text-right">
            <p>© Copyright 2025. All Rights Reserved.</p>
            <div className="mt-2">
              <p className="font-bold">OKNENOR L.L.C-FZ</p>
              <p>Meydan Grandstand, 6th floor, Meydan Road, Nad Al Sheba, Dubai, U.A.E.</p>
              <p>License Number: 2423010.01</p>
            </div>
          </div>
        </div>
        <DubaiTime/>
      </div>
    </footer>
  );
};

export default Footer;
