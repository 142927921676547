// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';

// Define the resources
const resources = {
  en: {
    translation: enTranslation
  },
  ar: {
    translation: arTranslation
  }
};

// Retrieve stored language preference or default to 'en'
const storedLang = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next) // Integrates i18n with React
  .init({
    resources,
    lng: storedLang, // Initial language
    fallbackLng: 'en', // Fallback language
    debug: true, // Enable debug mode for troubleshooting

    interpolation: {
      escapeValue: false // React already protects from XSS
    },
    react: {
      useSuspense: false // Disables suspense to prevent issues in certain setups
    }
  });

export default i18n;
