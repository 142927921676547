// src/components/LanguageSwitcher.jsx
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'; // Import PropTypes for type checking

const LanguageSwitcher = ({ isDark }) => { // Destructure isDark from props
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang); // Persist language choice
  };

  useEffect(() => {
    const storedLang = localStorage.getItem('language');
    if (storedLang && storedLang !== i18n.language) {
      i18n.changeLanguage(storedLang);
    }
  }, [i18n]);

  return (
    <div className="flex space-x-2">
      <button
        onClick={() => changeLanguage('en')}
        className={`px-2 py-1 rounded ${
          i18n.language === 'en'
            ? `bg-gray-600 text-white`
            : isDark
            ? `bg-transparent text-white hover:bg-gray-700`
            : `bg-transparent text-black hover:bg-gray-200`
        }`}
      >
        English
      </button>
      <button
        onClick={() => changeLanguage('ar')}
        className={`px-2 py-1 rounded ${
          i18n.language === 'ar'
            ? `bg-gray-600 text-white`
            : isDark
            ? `bg-transparent text-white hover:bg-gray-700`
            : `bg-transparent text-black hover:bg-gray-200`
        }`}
      >
        العربية
      </button>
    </div>
  );
};

// Define PropTypes for better type checking and documentation
LanguageSwitcher.propTypes = {
  isDark: PropTypes.bool.isRequired,
};

export default LanguageSwitcher;
