import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Container from "./Container.jsx";

const ContactUs = ({ onClose }) => {
  const formRef = useRef(null);

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev) => ({ ...prev, [e.target.name]: "" }));
  };

  const validate = () => {
    let errors = {};
    if (!formState.name) errors.name = "Name is required.";
    if (!formState.email) errors.email = "Email is required.";
    if (!formState.message) errors.message = "Message is required.";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();

    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      emailjs
        .sendForm(
          "oknenor",
          "oknenor_message",
          formRef.current,
          "7ZmwnZCAYddVyKOjE"
        )
        .then(
          (result) => {
            console.log("EmailJS Success:", result.text);
            setFormState({ name: "", email: "", message: "" });
            setFormErrors({});
            setSubmitted(true);
            setTimeout(() => setSubmitted(false), 5000);
          },
          (error) => {
            console.error("EmailJS Error:", error.text);
            alert("An error occurred while sending your message. Please try again.");
          }
        );
    }
  };

  return (
    <div className="relative h-full w-full flex flex-col overflow-y-auto">
      {/* Close button */}
      <button
        onClick={onClose}
        className="absolute top-4 sm:left-4 right-4 flex items-center justify-center w-8 h-8 border border-gray-300 bg-white shadow transition duration-300 hover:text-white hover:bg-black hover:shadow-md active:scale-95"
        aria-label="Close contact form"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-4 h-4"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>


      <div className="pt-12 pb-6 px-4 sm:px-8">
        <Container>
          <div className="lg:w-2/3 text-center mx-auto">
            <h2 className="text-gray-900 font-bold text-2xl md:text-2xl xl:text-4xl">
            Interested in solving your problems with Oknenor software?
            </h2>
            <p className="mt-8 text-gray-700">
              We&apos;d love to hear from you. Fill out the form below to start a conversation!
            </p>

            {submitted && (
              <div className="my-6 text-center text-green-500 font-semibold animate-pulse">
                Your message has been sent!
              </div>
            )}

            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="mt-12 max-w-xl mx-auto space-y-6"
            >
              <div>
                <label className="block text-gray-700">Name</label>
                <input
                  name="name"
                  type="text"
                  value={formState.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  className={`w-full mt-2 p-3 rounded-lg focus:outline-none ${formErrors.name ? "border-2 border-red-500" : "border border-gray-300"
                    } bg-white text-gray-700 placeholder-gray-400`}
                />
                {formErrors.name && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
                )}
              </div>

              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  name="email"
                  type="email"
                  value={formState.email}
                  onChange={handleChange}
                  placeholder="your.email@example.com"
                  className={`w-full mt-2 p-3 rounded-lg focus:outline-none ${formErrors.email ? "border-2 border-red-500" : "border border-gray-300"
                    } bg-white text-gray-700 placeholder-gray-400`}
                />
                {formErrors.email && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                )}
              </div>

              <div>
                <label className="block text-gray-700">Message</label>
                <textarea
                  name="message"
                  value={formState.message}
                  onChange={handleChange}
                  rows="5"
                  placeholder="Your Message"
                  className={`w-full mt-2 p-3 rounded-lg focus:outline-none ${formErrors.message ? "border-2 border-red-500" : "border border-gray-300"
                    } bg-white text-gray-700 placeholder-gray-400`}
                ></textarea>
                {formErrors.message && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.message}</p>
                )}
              </div>

              <div className="mt-8">
                <button
                  type="submit"
                  className="relative flex h-11 w-full items-center justify-center px-6 
                            before:absolute before:inset-0 before:rounded-full before:bg-primary 
                            before:transition before:duration-300 hover:before:scale-105 
                            active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-base font-semibold text-white">
                    Send Message
                  </span>
                </button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ContactUs;
