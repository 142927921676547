import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8 mt-20 text-gray-800">
      <h1 className="text-4xl font-bold text-indigo-600 mb-8">Terms and Conditions</h1>
      <p className="text-lg mb-6">
        Welcome to <span className="font-semibold">Oknenor</span>. By accessing
        and using our website, you agree to the following terms and conditions.
        Please read them carefully.
      </p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          1. Acceptance of Terms
        </h2>
        <p>
          Your use of this website signifies your agreement to these terms. If
          you do not agree, please discontinue use immediately.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          2. Use of Services
        </h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>Use our services only for lawful purposes.</li>
          <li>Any unauthorized use is strictly prohibited.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          3. Intellectual Property
        </h2>
        <p>
          All content, including text, images, and graphics, is the property of{" "}
          <span className="font-semibold">Oknenor</span> and is protected by
          copyright laws. Any unauthorized use is strictly prohibited.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          4. User Responsibilities
        </h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            Ensure the accuracy of any information you provide while using our
            services.
          </li>
          <li>
            Avoid actions that disrupt or harm the functionality of the
            website.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          5. Limitation of Liability
        </h2>
        <p>
          <span className="font-semibold">Oknenor</span> is not liable for any
          damages resulting from your use of our services, to the extent
          permitted by law.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          6. Third-Party Links
        </h2>
        <p>
          Our website may include links to third-party websites. We do not
          endorse or assume responsibility for the content or practices of
          these external sites.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          7. Changes to Terms
        </h2>
        <p>
          We reserve the right to modify these terms at any time. Continued use
          of our website indicates acceptance of the updated terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
          8. Governing Law
        </h2>
        <p>
          These terms are governed by the laws applicable in your jurisdiction
          and are subject to the legal framework of governing authorities.
        </p>
      </section>

      <section>
        <p className="text-lg font-semibold text-center mt-12">
          Thank you for using <span className="font-bold">Oknenor</span>.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
