import React, { useState, useEffect } from "react";

const DubaiTime = () => {
  const [dubaiTime, setDubaiTime] = useState(null);
  const [error, setError] = useState(null);

  // Backend URL from environment variables
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchDubaiTime = async () => {
      try {
        // Fetch time from the backend
        const response = await fetch(`${backendUrl}/api/dubai-time`);
        if (!response.ok) {
          throw new Error("Failed to fetch Dubai time");
        }
        const data = await response.json();
        setDubaiTime(data.time);
      } catch (err) {
        setError(err.message);
      }
    };

    // Fetch the time immediately and refresh it every minute
    fetchDubaiTime();
    const interval = setInterval(fetchDubaiTime, 60000);

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [backendUrl]); // Include backendUrl as a dependency

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Current Dubai Time</h1>
      {dubaiTime ? <p>{dubaiTime}</p> : <p>Loading...</p>}
    </div>
  );
};

export default DubaiTime;
