import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import {
  FaBullhorn,
  FaEdit,
  FaRocket,
  FaChartBar,
  FaLightbulb,
} from "react-icons/fa";

const FeatureCard = ({ icon, title, description, gradient, iconColor, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ 
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
      delay: index * 0.15 
    }}
    className={`relative group cursor-pointer overflow-hidden rounded-3xl ${gradient} p-1`}
  >
    <div className="bg-stone-900/90 rounded-[22px] p-6 h-full backdrop-blur-xl transition-all duration-500 group-hover:bg-stone-900/70">
      <motion.div 
        whileHover={{ rotate: 12 }}
        whileTap={{ scale: 0.9 }}
        className={`w-16 h-16 flex items-center justify-center rounded-2xl text-3xl mb-4 
                    ${iconColor} shadow-lg shadow-current/50`}
      >
        {icon}
      </motion.div>
      
      <h3 className="text-2xl font-black mb-2 bg-gradient-to-r from-stone-200 to-stone-400 bg-clip-text text-transparent">
        {title}
      </h3>
      
      <p className="text-base text-stone-300 leading-relaxed">
        {description}
      </p>

      <div className="absolute -right-16 -bottom-16 w-48 h-48 bg-stone-200/10 rounded-full blur-3xl group-hover:bg-stone-200/20 transition-all duration-700 group-hover:scale-150" />
    </div>
  </motion.div>
);

const Features = () => {
  const { t } = useTranslation();

  // Map each card to the corresponding translation keys
  const services = [
    {
      icon: <FaBullhorn />,
      title: t("features.services.socialMediaMagic.title"),
      description: t("features.services.socialMediaMagic.description"),
      gradient: "bg-gradient-to-br from-amber-700 via-orange-800 to-red-900",
      iconColor: "text-amber-500",
    },
    {
      icon: <FaEdit />,
      title: t("features.services.contentThatClicks.title"),
      description: t("features.services.contentThatClicks.description"),
      gradient: "bg-gradient-to-br from-emerald-800 via-green-900 to-teal-900",
      iconColor: "text-emerald-500",
    },
    {
      icon: <FaRocket />,
      title: t("features.services.landingPagesThatLaunch.title"),
      description: t("features.services.landingPagesThatLaunch.description"),
      gradient: "bg-gradient-to-br from-stone-700 via-neutral-800 to-zinc-900",
      iconColor: "text-stone-400",
    },
    {
      icon: <FaChartBar />,
      title: t("features.services.adCampaignMastery.title"),
      description: t("features.services.adCampaignMastery.description"),
      gradient: "bg-gradient-to-br from-slate-700 via-gray-800 to-neutral-900",
      iconColor: "text-slate-400",
    },
    {
      icon: <FaLightbulb />,
      title: t("features.services.strategySuperpowers.title"),
      description: t("features.services.strategySuperpowers.description"),
      gradient: "bg-gradient-to-br from-yellow-800 via-amber-900 to-orange-900",
      iconColor: "text-yellow-500",
    },
  ];

  return (
    <section className="relative py-16 overflow-hidden bg-gradient-to-b from-stone-800 via-neutral-900 to-zinc-950">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,rgba(120,113,108,0.2),transparent_70%)]" />
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_70%,rgba(168,162,158,0.15),transparent_70%)]" />

      <div className="container mx-auto px-4 relative">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-5xl font-black mb-4 py-3 bg-gradient-to-r from-amber-200 via-stone-300 to-neutral-400 bg-clip-text text-transparent">
            {t("features.heading")}
          </h2>
          <p className="text-lg text-stone-400 max-w-xl mx-auto">
            {t("features.subheading")}
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
          {services.map((service, index) => (
            <FeatureCard key={index} {...service} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
