// Navigation.jsx
import React, { useState, useEffect } from "react";
import Container from "./Container.jsx";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';

const navLinks = [
  { to: "/#solution", label: "solution" },
  { to: "/#reviews", label: "reviews" },
];

const Navigation = ({ openContact }) => {
  const { t } = useTranslation();
  const [atTop, setAtTop] = useState(true);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setAtTop(window.scrollY < 10);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleHamburgerClick = () => {
    setIsToggled(!isToggled);
  };

  const handleNavLinkClick = () => {
    setIsToggled(false);
  };

  const baseNavClasses = `fixed top-0 left-0 z-40 w-full transition-colors duration-300 ${
    isToggled ? "bg-black text-white" : atTop ? "bg-transparent text-black" : "bg-white text-black"
  }`;

  const mobileLayerClasses = [
    "fixed inset-0 z-10 h-screen w-screen bg-black text-white transition duration-300 lg:hidden",
    isToggled ? "opacity-100 visible" : "opacity-0 invisible",
  ].join(" ");

  const desktopOverlayClasses = [
    "fixed inset-0 z-30 flex items-center justify-center bg-black text-white transition duration-300 hidden lg:flex",
    isToggled ? "opacity-100 visible" : "opacity-0 invisible",
  ].join(" ");

  // Updated Hamburger Lines for Smooth Transformation with Reduced Size
  const hamburgerLine1Classes = [
    "h-0.5 w-5 bg-current transition-all duration-300",
    isToggled ? "rotate-45 translate-y-1" : "mb-1",
  ].join(" ");

  const hamburgerLine2Classes = [
    "h-0.5 w-5 bg-current transition-all duration-300",
    isToggled ? "opacity-0" : "mb-1",
  ].join(" ");

  const hamburgerLine3Classes = [
    "h-0.5 w-5 bg-current transition-all duration-300",
    isToggled ? "-rotate-45 -translate-y-1" : "",
  ].join(" ");

  return (
    <header>
      <nav className={baseNavClasses}>
        <Container>
          <div className="relative flex flex-wrap items-center justify-between gap-6 py-2 md:gap-0 md:py-3">
            <div className="relative z-40 flex w-full justify-between md:px-0 lg:w-max">
              <a href="/#home" aria-label="logo" className="flex items-center space-x-2">
                <div aria-hidden="true" className="flex space-x-1">
                  <div
                    className={`h-4 w-8 rounded-full ${
                      isToggled ? "bg-white" : "bg-gray-900"
                    }`}
                  />
                  <div className={`h-4 w-2 ${isToggled ? "bg-white" : "bg-black"}`} />
                </div>
                <span className="text-xl font-semibold">OKNENOR</span>
              </a>
            </div>

            {/* Desktop Menu */}
            <div className="hidden lg:flex items-center gap-3">
              {/* Language Switcher */}
              <LanguageSwitcher isDark={isToggled} />

              {/* Get Started Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  openContact();
                  setIsToggled(false);
                }}
                className={`flex items-center justify-center border py-1.5 px-20 shadow transition duration-300 ${
                  isToggled
                    ? "bg-black border-white text-white hover:bg-white hover:text-black"
                    : "bg-white border-black text-black hover:text-white hover:bg-black"
                }`}
                style={{ height: "40px" }} // Reduced height from 48px to 40px
              >
                {t('getStarted')}
              </button>

              {/* Desktop Hamburger */}
              <button
                aria-label="hamburger-desktop"
                id="hamburger-desktop"
                onClick={handleHamburgerClick}
                className={`flex flex-col items-center justify-center gap-0.5 transition-colors duration-300 border py-1.5 px-3 ${
                  isToggled ? "bg-black text-white border-white" : "bg-white text-black border-black"
                }`}
                style={{ height: "40px", width: "44px" }} // Reduced height and width from 48px to 44px
              >
                <span className={hamburgerLine1Classes}></span>
                <span className={hamburgerLine2Classes}></span>
                <span className={hamburgerLine3Classes}></span>
              </button>
            </div>

            {/* Mobile Full-Screen Menu */}
            <div id="navLayer" aria-hidden="true" className={mobileLayerClasses}>
              <div className="flex flex-col items-center justify-center h-full">
                <ul className="text-center">
                  {navLinks.map((link) => (
                    <li key={link.to} className="mb-5 text-2xl font-bold">
                      <a href={link.to} onClick={handleNavLinkClick} className="hover:text-primary">
                        {t(link.label)}
                      </a>
                    </li>
                  ))}
                  
                  {/* Language Switcher in Mobile Menu */}
                  <li className="mt-8">
                    <LanguageSwitcher isDark={isToggled} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </nav>

      {/* Desktop Full-Screen Menu */}
      <div className={desktopOverlayClasses}>
        <ul className="text-center">
          {navLinks.map((link) => (
            <li key={link.to} className="mb-5 text-2xl font-bold">
              <a href={link.to} onClick={handleNavLinkClick} className="hover:text-primary">
                {t(link.label)}
              </a>
            </li>
          ))}
          
          {/* Language Switcher in Desktop Overlay Menu */}
          <li className="mt-8">
            <LanguageSwitcher isDark={isToggled} />
          </li>
        </ul>
      </div>

      {/* Mobile bottom bar (visible on mobile only) */}
      <div className="fixed bottom-0 left-0 right-0 z-50 flex items-center justify-between p-3 lg:hidden">
        {/* Mobile Hamburger */}
        <button
          aria-label="hamburger-mobile"
          id="hamburger-mobile"
          onClick={handleHamburgerClick}
          className={`flex flex-col items-center justify-center gap-0.5 transition-colors duration-300 border py-1.5 px-3 ${
            isToggled ? "bg-black text-white border-white" : "bg-white text-black border-black"
          }`}
          style={{ height: "40px", width: "44px" }} // Reduced height and width from 48px to 44px
        >
          <span className={hamburgerLine1Classes}></span>
          <span className={hamburgerLine2Classes}></span>
          <span className={hamburgerLine3Classes}></span>
        </button>

        {/* Get Started Button */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            openContact();
            setIsToggled(false);
          }}
          className={`flex items-center justify-center border py-1.5 px-5 shadow transition duration-300 ${
            isToggled
              ? "bg-black border-white text-white hover:bg-white hover:text-black"
              : "bg-white border-black text-black hover:text-white hover:bg-black"
          }`}
          style={{ height: "40px" }} // Reduced height from 48px to 40px
        >
          {t('getStarted')}
        </button>
      </div>
    </header>
  );
};

export default Navigation;